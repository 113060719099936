import { Link } from "gatsby";
import React from "react";
import Button from "../../components/Button";
import "./VideoBanner.scss";

const ImageBannerWithButton = (props) => {


  return (
    // <div className="imageBanner" id="bgimg">
    <div className="video-wrapper" id="bgimg">
      <img className="imageBanner" src={props.VideobannerData.BannerImage} alt={props.VideobannerData.ImageBannerTitle} id="bgimg"/>
      <div className="overlay">
        <div className="body-container">
          <h1
            className={
              props.VideobannerData.autoWidth
                ? `bnr-img-title1 ${props.VideobannerData.subtitleclassname}`
                : `bnr-img-title ${props.VideobannerData.subtitleclassname}`
            }
          >
            {props.VideobannerData.bnrImgTitle}
          </h1>
        </div>
       {props.VideobannerData.displayButton !== 'no' &&  <div className="image-banner-btn-left">
       <Link target="_blank" style={{display:'inline-block'}} className="services-link" to={props.VideobannerData.route}>
          <Button
            customClassName="image-banner-resize-btn"
            variant={props.VideobannerData.variant}
            color={props.VideobannerData.color}
            label={props.VideobannerData.label}
            // routepath={props.VideobannerData.route} 
            tooltip={props.tooltip} 
          /></Link>
        </div>}</div>
      </div>
  );
};

export default ImageBannerWithButton;
