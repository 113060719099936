import Grid from "@material-ui/core/Grid";
import React from "react";
import Button from "../../components/Button";
import RightArrow from "../../assets/Icons/go.svg";
import clutchLogo from "../../assets/Images/Awards/recognized_by@2x.png";


const AwardsOverviewGrid = () => {
    return (
<div className="body-container">
<Grid container direction="row" spacing={3}>
    <Grid item xs={12} sm={12} md={3} >
    <img className="recognition-img" src={clutchLogo} alt="Clutch" height="50px" />
    </Grid>
    <Grid item xs={12} sm={12} md={9} >
    <div className="mt-30">
    <div className="mbl-br-hide title-header">
    Recognised as a <br/>
  <span className="red-line3letter">Hig</span>h growth company
</div>
<div className="recoginition-content">Lorem ipsum dolor sit amet, ea vitae mollis cum, ut errem saepe viderer pri. Te nam sumo rebum. Vim eros omnium eleifend no, soluta incorrupte ne pri. Sea affert nullam ei. Ea quo facete saperet. Ea liber neglegentur mel, meis referrentur eu duo. Duo ad labitur temporibus. Id audiam mentitum pro, pri ornatus antiopam gloriatur no. Has ei ferri dicant everti, mei id agam latine. Eu eos minim honestatis, no probatus imperdiet pro. Ei eam dicat homero maiestatis. Choro option postulant ex vel, lorem voluptatibus an vix. Ei nostrum complectitur mea, ut vim nullam imperdiet. Duo et quod tincidunt intellegam, ad quis aperiri qui. Iudico option inermis cu pri, civibus tacimates ea nam. Mei aeque semper accusam ut, iisque fierent ex usu. Ius no dicam explicari disputationi, pro lorem civibus fuisset at, sea an commune dissentiet signiferumque. Te inani assueverit scriptorem est. Et viris tation duo. Per quot congue ut, ubique delectus praesent ex vix, solet aperiri vituperata cum an. Vidisse detraxit pro ei, vel numquam nonumes necessitatibus ea, ne omnes doming Mei. Cu omnium definitionem usu, pro id constituto philosophia, his equidem debitis vivendum ei. Graecis deserunt sadipscing te est, sint exerci persequeris sit id, diam veritus sit et.</div>    
</div>
        {/* <Button
          icon={
            (
              <img
                className="effect-btn-svgicon"
                src={RightArrow}
                alt="click"
              />
            )
          }
          customClassName= "effect-btn1 no-padding-left mt-20"
          color="primary"
          tooltip="yes"
          label="Find out More"
          // routepath={item.card1.route}
        /> */}
        </Grid>
        </Grid>
        </div>

);
};

export default AwardsOverviewGrid;