import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DataService from "../services/api/data_services";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import RightArrow from "../assets/Icons/go.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import ImageBannerWithButton from "../templates/ImageBannerWithButton";
import { default as ImageBnr } from "../assets/Images/Awards/Awards&Recognition_banner@2x.png";
import awardsForMobile from "../assets/Images/About Us/awards____2x.png";
import BackArrowIcon from "../assets/Images/accordian.svg";
import ogimage from "../assets/Images/ogImages/aboutus@2x.png";
import clutchLogo from "../assets/Images/Awards/recognized_by@2x.png";
import Award2 from "../assets/Images/WhoWeWorkWith/awards/clutch-b2b-award.png";
import Award5 from "../assets/Images/WhoWeWorkWith/awards/clutch-top-100-companies.png";
import Award4 from "../assets/Images/WhoWeWorkWith/awards/digital-entrepreneur-awards.png";
import Award3 from "../assets/Images/WhoWeWorkWith/awards/mobile-app-daily-top-app-development-company-award.png";
import Award1 from "../assets/Images/WhoWeWorkWith/awards/Mobile-App-Developers-2020-award.png";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import { BASE_SITE_URL } from "../shared/constants";

//Box
import Box from "../templates/Box";

import ImageList from "../templates/ImageList";
import ImageListDynamic from "../templates/ImageListDynamic";
//Info View
import Infoview from "../templates/InfoView";
import Miniview from "../templates/MiniView";
import AwardsOverviewGrid from "../templates/AwardsOverviewGrid";
//Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "black",
    fontWeight: "500",
    fontSize: "40px",
    lineHeight: "40px",

    ["@media only screen and (min-width: 768px) and (max-width: 1024px) "]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "17px",
    },
  },
  subtitle: {
    color: "black",
    fontSize: "20px",
    paddingTop: "11px",
    lineHeight: "25px",
    ["@media only screen and (min-width: 768px) and (max-width: 1024px) "]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "17px",
    },
    ["@media only screen  (max-width: 780px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "14px",
    },
  },

  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));
const AboutUs = () => {
  const classes = useStyles();
  const [awardsData, setAwardsData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [awardRawData, setAwardRawData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [articleRawData, setArticleRawData] = useState([]);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/awards-article?type=Article&limit=${limit}&page=${page}`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
  }, [limit, page]);

  useEffect(() => {
    DataService.getData(
      `/admin/api/awards?limit=${limit}&page=${page}&type=Awards`
    ).then((response) => {
      setAwardsData([...awardsData, ...response.data]);
      setAwardRawData(response);
      if (page === 1) {
        setPage(2);
      }
    });
  }, [limit, page]);

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "CAREER",
    tip: "CAREER",
    tooltip: "yes",
    boxBtn1Title: "Join our team",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1SubTitle:
      "If you're a designer or a developer who loves to challenge conventions, make the ryte move!",
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "GET IN TOUCH",
    boxBtn2Title: "Let’s talk",
    tip1: "GET IN TOUCH",
    boxBtn2SubTitle:
      "Have you got an exciting project that you'd like to discuss?",
    route1: "/contact-us",
    route2: "/contact-us",
  };
  const ImagelistData = {
    ImageListButtonColor: "primary",
    ImageListButtonLabel: "Find out More",
    width: "113px",
    tag: "h4",
    awards: [
      {
        awardImage: Award1,
        awardTitle: "Top Mobile App Developers",
        awardSubTitle: "For 3 consecutive years",
        awardAlt: "awardAlt1",
      },
      {
        awardImage: Award2,
        awardTitle: "Top B2B Companies",
        awardSubTitle: "For 4 consecutive years",
        awardAlt: "awardAlt2",
      },
      {
        awardImage: Award3,
        awardTitle: "Top Cross-platform app development companies",
        awardSubTitle: "For 4 consecutive years",
        awardAlt3: "awardAlt3",
      },
      {
        awardImage: Award4,
        awardTitle: "Digital Entrepreneur Awards",
        awardSubTitle: "For 2 consecutive years",
        awardAlt4: "awardAlt4",
      },
      {
        awardImage: Award5,
        awardTitle: "Clutch Top 100 Companies",
        awardSubTitle: "For 2 consecutive years",
        awardAlt5: "awardAlt5",
      },
    ],
  };
  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide">
        <span className="red-line3letter">Awa</span>rds & Recognition
      </div>
    ),
    tag: "h2",
    width: "87px",
    InfoviewContent: (
      <>
        We innovate to improve the way the world works and lives. See our
        accomplishments as a global leader, great employer and corporate
        citizen.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Miniview
   */

  const miniviewData2 = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tip: "GET IN TOUCH",
    tooltip: "yes",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  // Mobile movie slider
  const mobMovieSlider2 = {
    imageUrl: awardsForMobile,
  };
  /**
   * Video Banner
   */

  const VideobannerData = {
    variant: "contained",
    color: "primary",
    displayButton: "no",
    // label: "Discover Digiryte",
    bnrImgTitle: "Awards & Recognition",
    ImageBannerTitle: "Awards & Recognition",
    BannerImage: ImageBnr,
    route: "/about-us",
  };
  return (
    <Layout
      seo={{
        title: "Awards & Recognition | Digiryte",
        ogtitle: "Awards & Recognition | Digiryte",
        description:
          "Our mission is to build superior quality software using the latest technologies to help startups and enterprises achieve faster growth in their businesses.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/awards-and-recognitions`,
      }}
    >
      {/* <ImageBanner imageBannerData={ImageBannerData} /> */}
      <ImageBannerWithButton VideobannerData={VideobannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Home"
          routepath="/"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Home
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <AwardsOverviewGrid />
      <div className="hide-in-mobile">
        <ImageListDynamic
          ImagelistData={awardsData.length > 0 ? ImagelistData : ""}
          awardsData={awardsData}
        />
      </div>
      {awardRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page === 1 ? page + 2 : page + 1);
              setLimit(5);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE AWARDS"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="Load more"
              // tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="hide-in-web body-container">
        <h3 className="title-header">Awards & Recognitions</h3>
      </div>
      <MobileMovieSlider movieSlider={mobMovieSlider2} slideid="slide3" />

      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container ttc">
            <h3 className="title-header">
              <span className="red-line3letter">Dig</span>iryte News
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              miniviewData2={miniviewData2}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page === 1 ? page + 2 : page + 1);
              setLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70" />
      <Box BoxData={BoxData} />
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default AboutUs;
